<template>
<!-- pc -->
  <div v-show="!mobile" class="portfolio">
    <h1 class="title">Portfolio</h1>
    <h3 class="subtitle">Some examples of our previous work</h3>

    <div class="imgBox">
        <img id="img" src="../assets/fotos/portfolio/IMG_7065.jpg" />
        <img id="img" src="../assets/fotos/portfolio/IMG_5293.jpg"  />
        <img id="img" src="../assets/fotos/portfolio/IMG_7062.jpg"  />
        <img id="img" src="../assets/fotos/portfolio/IMG_7063.jpg"  />
        <img id="img" src="../assets/fotos/portfolio/IMG_7064.jpg"  />
        <!--<img id="img" style="transform: rotate(90deg);" src="../assets/fotos/portfolio/IMG_7066.jpg"  />-->
        <img id="img" src="../assets/fotos/portfolio/IMG_7068.jpg"  />
        <img id="img" src="../assets/fotos/portfolio/IMG_7069.jpg"  />
        <img id="img" src="../assets/fotos/portfolio/IMG_7070.jpg"  />
        <img id="img" src="../assets/fotos/portfolio/IMG_7071.jpg"  />

    </div>
  </div>

<!-- mobile -->
  <div v-show="mobile" class="portfolioMobile">
   <h1 class="titleMobile">Portfolio</h1>
    <!-- <h3 class="subtitleMobile">Some examples of our previous work</h3> -->

    <div class="imgBoxMobile">
        <img id="imgMobile" src="../assets/fotos/portfolio/IMG_7065.jpg" />
        <img id="imgMobile" src="../assets/fotos/portfolio/IMG_5293.jpg"  />
        <img id="imgMobile" src="../assets/fotos/portfolio/IMG_7062.jpg"  />
        <img id="imgMobile" src="../assets/fotos/portfolio/IMG_7063.jpg"  />
        <img id="imgMobile" src="../assets/fotos/portfolio/IMG_7064.jpg"  />
        <!--<img id="img" style="transform: rotate(90deg);" src="../assets/fotos/portfolio/IMG_7066.jpg"  />-->
        <img id="imgMobile" src="../assets/fotos/portfolio/IMG_7068.jpg"  />
        <img id="imgMobile" src="../assets/fotos/portfolio/IMG_7069.jpg"  />
        <img id="imgMobile" src="../assets/fotos/portfolio/IMG_7070.jpg"  />
        <img id="imgMobile" src="../assets/fotos/portfolio/IMG_7071.jpg"  />

    </div>
  </div>
</template>
<script>
export default {
  name: "Navigation",
  data() {
    return {
      scrolledNav: null,
      mobile: true,
      mobileNav: null,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },

    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
      return;
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
  },
};
</script>

<style lang="scss" scoped>

  .portfolio {
    margin: 3%;
  }

  #img {
    width: 100%;
    flex: 1 0 33%;
    margin: 4px;
    height: 100%;
  }
  .about{
    //margin-top: 10px;
    margin: 20px 50px 80px 50px;
    //margin-left: auto;
    padding: 10px;

  }
  .imgBox {
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    // justify-content: center;
    // column-gap: 10px;
  }

  .imgBoxRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
  }
  .infoBoxesMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    //column-gap: 150px;
  }
  .box{
    margin: 40px 0 0 0px;
    //width: 500px;
    //padding: 10px;
    text-align: center;
    // border-left: 4px;
    // border-left-color: #5b1c9f;
    // border-left-style: solid;
    // border-bottom-left-radius: 4px;
    // border-top-left-radius: 4px;
  }
  hr{
    //text-align: center;
    width: 150px;
    border-bottom: 2px solid #5b1c9f;
    //margin-left: 0px;
    //margin-left: 30px;
    //margin-right: 30px;
  }
  .title {
    text-transform: uppercase;
    //float: left;
    font-size: 60px;
    //font-weight: 200;
    font-weight: 200;
    font-style: normal;
    color: rgb(55, 54, 54);
    margin-left: auto;
    text-align: left;
    margin-bottom: 0px;
  }
  .subtitle {
    text-transform: uppercase;
    //float: left;
    font-size: 20px;
    //font-weight: 200;
    font-weight: 200;
    font-style: normal;
    color: rgb(55, 54, 54);
    margin-top:0px;
    margin-left: auto;
    text-align: left;
  }

  .text {
    font-size: 25px;
  font-weight: 300;
  }
  h3 {
    font-weight: 400;
  }
  // mobile css
  .imgBoxMobile {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    // column-gap: 10px;
  }
.titleMobile {
    text-transform: uppercase;
    //float: left;
    font-size: 40px;
    //font-weight: 200;
    font-weight: 200;
    font-style: normal;
    color: black;
    margin: auto;
    text-align: center;

  }
  .subtitleMobile {
    text-transform: uppercase;
    //float: left;
    font-size: 20px;
    //font-weight: 200;
    font-weight: 200;
    font-style: normal;
    color: rgb(55, 54, 54);
    margin-top:0px;
    margin-left: auto;
    text-align: left;
  }

   #imgMobile {
    width: 100%;
    flex: 1 0 100%;
    margin: auto;
    margin-top: 4px;
    height: 100%;
    text-align: center;
  }

  .portfolioMobile{
    //margin-top: 10px;
    //margin: 20px 50px 80px 50px;
    margin: auto;
    //margin-left: auto;
    padding: 10px;
    //width: 100%;
    text-align: center;

  }

</style>
