<template>
<!-- pc -->
  <div v-show="!mobile" class="about">
    <h1 class="title">Contact Us</h1>
    <div class="infoBoxes">
    <div class="box">
      <h2>Address</h2>
      <hr>
      <h3>Rua da Indústria Metalúrgica, 916 B<br/>
      2430-528 Marinha Grande Portugal
      </h3>

    </div>
    <div class="box">
      <h2>Telephone</h2>
      <hr>
      <h3>+351 244 024 482</h3>

    </div>
    <div class="box">
      <h2>E-mail</h2>
      <hr>
      <h3>info@jorgecastanho.pt</h3>

    </div>

    </div>
    <div class="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.7471083966193!2d-8.902424384392345!3d39.74533117944891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd220c15a1a768e5%3A0x8ffebeaf59c25d07!2sJorge%20Castanho%20Lda!5e0!3m2!1sen!2spt!4v1668451207189!5m2!1sen!2spt" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

  </div>
<!-- mobile -->
  <div v-show="mobile" class="about">
    <h1 class="titleMobile">Contact Us</h1>
    <div class="infoBoxesMobile">
    <div class="box">
      <h2>Address</h2>
      <hr>
      <h3>Rua da Indústria Metalúrgica, 916 B<br/>
      2430-528 Marinha Grande Portugal</h3>

    </div>
    <div class="box">
      <h2>Telephone</h2>
      <hr>
      <h3>+351 244 024 482</h3>

    </div>
    <div class="box">
      <h2>E-mail</h2>
      <hr>
      <h3>info@jorgecastanho.pt</h3>

    </div>

    </div>
    <div class="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3067.7471083966193!2d-8.902424384392345!3d39.74533117944891!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd220c15a1a768e5%3A0x8ffebeaf59c25d07!2sJorge%20Castanho%20Lda!5e0!3m2!1sen!2spt!4v1668451207189!5m2!1sen!2spt" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "Navigation",
  data() {
    return {
      scrolledNav: null,
      mobile: true,
      mobileNav: null,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },

    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
      return;
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
  .about{
    //margin-top: 10px;
    margin: 20px 50px 80px 50px;
    //margin-left: auto;
    padding: 10px;

  }
  .infoBoxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 150px;
  }
  .infoBoxesMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    //column-gap: 150px;
  }
  .box{
    margin: 40px 0 0 0px;
    //width: 500px;
    //padding: 10px;
    text-align: center;
    // border-left: 4px;
    // border-left-color: #5b1c9f;
    // border-left-style: solid;
    // border-bottom-left-radius: 4px;
    // border-top-left-radius: 4px;
  }
  .box hr{
    //text-align: center;
    width: 150px;
    border-bottom: 2px solid #5b1c9f;
    //margin-left: 0px;
    //margin-left: 30px;
    //margin-right: 30px;
  }
  .title {
    text-transform: uppercase;
    //float: left;
    font-size: 40px;
    //font-weight: 200;
    font-weight: 200;
    font-style: normal;
    color: black;
    margin-left: auto;
    text-align: center;
  }
  .titleMobile {
    text-transform: uppercase;
    //float: left;
    font-size: 30px;
    //font-weight: 200;
    font-weight: 200;
    font-style: normal;
    color: black;
    margin-left: auto;
    text-align: center;
  }
  .text {
    font-size: 25px;
    font-weight: 300;
  }
  .map {
    margin-top: 30px;
  }
  h3 {
    font-weight: 400;
  }
</style>
